import React, { useState } from "react";
import { IoSparkles } from "react-icons/io5";
import { Dialog } from "./Dialog";
import AiAssistChat from "./AiAssistChat";

interface AiAssistProps {
  formFillID: number;
}

export const AiAssistComponent: React.FC<AiAssistProps> = ({ formFillID }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)

  const handleOpenModal = () => {
    setModalOpen(true)
    setLoad(true)
  }
  
  const handleCloseModal = () => {
    setModalOpen(false)
    if(refresh){
      legacyRefreshViewAfterUpdateFormFill()
    }
  }

  const legacyRefreshViewAfterUpdateFormFill = () => {
    const url = `/form_fills/${formFillID}.js`
    $.ajax({
      url: url,
      success: function(_data) {
      },
      error: function (_error) {
        alert("Ocorreu um erro e tela será recarregada.")
        window.location.reload()
      }
    })
  }

  return (
    <>
      <button
        className="mdl-button mdl-js-button mdl-js-ripple-effect button-primary action-btn white-default light-border"
        onClick={handleOpenModal}
      >
        <IoSparkles fontSize={'1.25em'} />Consultar a IA
      </button>

      <Dialog
        isOpen={modalOpen} onClose={handleCloseModal} isBeta={true}
        title="IA Produttivo"
      >
        <AiAssistChat formFillID={formFillID} refresh={setRefresh} loadMessages={load} />
      </Dialog>
    </>
  );
}
