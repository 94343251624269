import React, { useState } from "react";
import { IoSparkles } from "react-icons/io5";
import { Dialog } from "../../../components/Dialog";
import AiAssistChat from "./AiAssistChat";

export const AiAssistButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)

  const handleOpenModal = () => {
    setModalOpen(true)
    setLoad(true)
  }
  
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <button
        className="produttivo-btn"
        onClick={handleOpenModal}
      >
        <IoSparkles fontSize={'1.25em'} />Pedir sugestão à IA
      </button>

      <Dialog
        isOpen={modalOpen} onClose={handleCloseModal} isBeta={true}
        title="IA Produttivo"
      >
        <AiAssistChat loadMessages={load} closeModal={handleCloseModal} />
      </Dialog>
    </>
  )
}
